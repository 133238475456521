<template src="./incident-data.html"></template>

<script>
import {getRiskRatingClass} from "@/utils/utils";
import SiteCharts from "@/modules/safety-module/components/site-charts/site-charts.vue";
import {mapGetters, mapState} from "vuex";
import IncidentsTable from "@/modules/safety-module/components/incidents-table/incidents-table.vue";
import SingleUserIncidentsTable
  from "@/modules/safety-module/components/single-user-incidents-table/single-user-incidents-table.vue";

export default {
  name: "incident-data",
  components: {SingleUserIncidentsTable, IncidentsTable, SiteCharts},
  data() {
    return {
      isLoading: false
    }
  },
  mounted() {
  },
  methods: {
    getRiskColor(risk){
      return getRiskRatingClass(risk)
    },
    onLoadEvent(payload){
      this.$emit('onLoadEvent', payload)
    },
    loadIncidentsTable(){
      if(this.selectedProject.types && this.selectedProject.types.length === 0){
        return true
      }else{
        return false
      }
    },
    loadSingleUserIncidentTable(){
      if(this.selectedProject.types && this.selectedProject.types.length > 0 && this.selectedProject.types.includes('DTP_SURVEY')){
        return true
      }else{
        return false
      }
    }
  },
  computed: {
    ...mapState({
      selectedProject: state => state.safetyModule.selectedProject,
      selectedDataDevice: state => state.safetyModule.selectedDataDevice,
      tableHeaderData: state => state.safetyModule.tableHeaderData,
    }),
    ...mapGetters({
      averageValues: 'safetyModule/getAverageValues',
      singleUserAverageValues: 'safetyModule/getSingleUserAverageValues',
    }),
    showRiskRating(){
      if (this.selectedProject && this.selectedProject.types && this.selectedProject.types.length === 0) {
        return true
      } else {
        return false
      }
    },
    showLowestPet(){
      if (this.selectedProject && this.selectedProject.types && this.selectedProject.types.length === 0) {
        return true
      } else {
        return false
      }
    },
    showTopSingleEvents(){
      if (this.selectedProject && this.selectedProject.types && this.selectedProject.types.includes('DTP_SURVEY')) {
        return true
      } else {
        return false
      }
    },

  }
}
</script>

<style scoped lang="scss" src="./incident-data.scss"></style>