import {Role} from "@/utils/utils";
import eventDetails from "@/modules/safety-module/views/event-details/event-details.vue";
import home from "@/modules/safety-module/views/home/home.vue";
const Module = () => import("./Module.vue");

const moduleRoute = {
  path: "/safety",
  component: Module,
  children: [
      {
      path: '/',
      name: 'safety',
      meta:{
        product: 'Realite Safety',
        title: 'Dashboard',
        isSubCategory: false,
        roles:[Role.ADMIN, Role.TRAFFIC_ENG],
        topBarTitle:'',
        permission: 'project:list',
        hasMenu: true,
        hasInternalTransition: false
      },
      component:  home
    },
    {
      path: '/safety/event-details/:eventId?/:projectId?/:dataDeviceId?/:pageNumber?',
      name: 'event-details',
      meta:{
        product: 'Realite Safety',
        title: 'Event details',
        isSubCategory: true,
        roles:[Role.ADMIN, Role.TRAFFIC_ENG],
        permission: 'project:list',
        hasMenu: true,
        topBarTitle: '',
        hasInternalTransition: false
      },
      props:true,
      component: eventDetails
    },
/*    {
      path: '/safety/pax-count',
      name: 'pax-count',
      meta:{
        product: 'Realite Safety',
        title: 'Pax count QA',
        isSubCategory: false,
        permission: 'project:list',
        roles:[Role.ADMIN],
        topBarTitle:'',
        hasMenu: true,
        hasInternalTransition: false
      },
      component: paxCountQa
    }*/

  ]
};

export default router => {
  router.addRoutes([moduleRoute]);
};
