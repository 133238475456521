import Vue from "vue";

export default {
    /**
     * Get all traffic sites by project
     * @param commit
     * @param projectId
     * @returns {Promise<void>}
     */
    /*    getTrafficSitesByProject: async ({commit}, projectId) => {
            const options = {
                method: 'POST',
                url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-traffic-sites/traffic-sites-list',
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    projectId: projectId
                }
            };
            await Vue.prototype.$http(options).then(function (response) {
                console.log('Get traffic sites response: ', response)
                commit('updateAllSites', response.data)
            }).catch(function (error) {
                console.log(error);
            });
        },*/
    createTrafficSiteMapConfiguration: async ({commit}, data) => {
        const options = {
            method: 'POST',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-safetyanalysis/traffic-site-map-configuration',
            headers: {
                'content-type': 'application/json'
            },
            data: data
        };
        return await Vue.prototype.$http(options).then(function (response) {
            console.log('Create traffic site map configuration: ', response)
            if (response.data && response.data.errorMessage) {
                return Error(response.data.errorMessage)
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    updateTrafficSiteMapConfiguration: async ({commit}, data) => {
        const options = {
            method: 'PUT',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-safetyanalysis/traffic-site-map-configuration',
            headers: {
                'content-type': 'application/json'
            },
            data: data
        };
        return await Vue.prototype.$http(options).then(function (response) {
            console.log('Update traffic site map configuration: ', response)
            if (response.data && response.data.errorMessage) {
                return Error(response.data.errorMessage)
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    deleteTrafficSiteMapConfiguration: async ({commit}, id) => {

        const options = {
            method: 'DELETE',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-safetyanalysis/traffic-site-map-configuration',
            headers: {
                'content-type': 'application/json'
            },
            data: {id: id}
        };
        return await Vue.prototype.$http(options).then(function (response) {
            console.log('Delete traffic site map configuration: ', response)
            //commit('updateAllSites', response.data)
            if (response.data && response.data.errorMessage) {
                return Error(response.data.errorMessage)
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    getTrafficSiteMapConfiguration: async ({commit}, dataDeviceId) => {
        const options = {
            method: 'GET',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-safetyanalysis/traffic-site-map-configuration',
            headers: {
                'content-type': 'application/json'
            },
            params: {dataDeviceId: dataDeviceId}
        };
        return await Vue.prototype.$http(options).then(function (response) {
            console.log('Get Traffic Site Map Configuration response: ', response)
            //commit('updateAllSites', response.data)
            if (response.data && response.data.errorMessage) {
                return Error(response.data.errorMessage)
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    createTMCReport: async ({commit}, data) => {
        const options = {
            method: 'POST',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-safetyanalysis/traffic-site-operations-report',
            headers: {
                'content-type': 'application/json'
            },
            data: data
        };
        return await Vue.prototype.$http(options).then(function (response) {
            console.log('Create traffic site map operations report: ', response)
            if (response.data && response.data.errorMessage) {
                return Error(response.data.errorMessage)
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    getOperationsReport: async ({commit}, siteId) => {
        const options = {
            method: 'GET',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-safetyanalysis/traffic-site-operations-report',
            headers: {
                'content-type': 'application/json'
            },
            params: {siteId: siteId}
        };
        return await Vue.prototype.$http(options).then(function (response) {
            console.log('Get traffic site map operations report: ', response)
            if (response.data && response.data.errorMessage) {
                return Error(response.data.errorMessage)
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    getAiKitReport: async ({commit}, dataDeviceId) => {

        let data = new FormData();
        data.append('dataDeviceId', dataDeviceId);

        const options = {
            method: 'POST',
            url: Vue.prototype.$env.VUE_APP_AI_KIT_API_URL + '/api/get_all_counting_results_by_dt_and_siteId_summary',
            headers: {
                'content-type': 'application/json'
            },
            data: data
        };
        return await Vue.prototype.$http(options).then(function (response) {
            console.log('Get data from AiKit: ', response)
            //commit('updateAllSites', response.data)
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    getAiKitSiteConfiguration: async ({commit}, dataDeviceId) => {
        let data = new FormData();
        data.append('dataDeviceId', dataDeviceId);
        const options = {
            method: 'POST',
            url: Vue.prototype.$env.VUE_APP_AI_KIT_API_URL + '/api/counting_results_get_all_unique_directions',
            headers: {
                'content-type': 'application/json'
            },
            data: data
        };
        return await Vue.prototype.$http(options).then(function (response) {
            console.log('Get data from AiKit: ', response)
            //commit('updateAllSites', response.data)
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    /**
     * Get conflict events by traffic site id
     * @param commit
     * @param siteId
     * @returns {Promise<*>}
     */
    getConflictEvents: async ({commit}, dataDeviceId) => {
        console.log('Get conflict events by dataDevice id: ', dataDeviceId)
        // create config object with necessary headers and data
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-safetyanalysis/search-conflict-events',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {dataDeviceId: dataDeviceId, eventsValid: 'validated'}
        };

        // send delete request using axios
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Events response: ', response.data)
                if (response.data && response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('setTableData', response.data)
                    commit('setSummary', response.data)
                    return response;
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getSummaryData: async ({commit}, projectId) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-safetyanalysis/get-report-conflict-events',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {projectId: projectId, eventsValid: 'validated', timezone: 'Australia/Melbourne'}
        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Summary data response: ', response.data)
                if (response.data && response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else {
                    commit('updateAllSites', response.data)
                }

                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    /**
     * Get video links
     * @param commit
     * @param video_id
     * @returns {Promise<*>}
     */
    startVideoDownload: async ({commit}, video_id) => {

        let data = {
            videoId: video_id
        }

        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-safetyanalysis/download-video-presigned-url',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Video response: ', response.data)
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });

    },
    getBulkVideoPresignedUrls: async ({commit}, videoIds) => {

        let data = {
            videoIds: videoIds
        }

        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-safetyanalysis/get-video-urls',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Video bulk response: ', response.data)
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });

    },

    /**
     * Get single road user data
     * @param commit
     * @param userId
     * @returns {Promise<*>}
     */
    getRoadUserData: async ({commit}, userId) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-safetyanalysis/road-user',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {id: userId}
        };


        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Road user response: ', response.data)
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getFilesList: async ({commit}, data) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v2-svoc/file-metadata-list',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Files list response: ', response.data)
                commit('setFileOperationsTableData', response.data)
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    prepareFileForUpload: async ({commit}, data) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v2-svoc/store-file',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Files prepare for upload response: ', response.data)
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    prepareDownloadFile: async ({commit}, fileId) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v2-svoc/file-download',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {id: fileId}
        };


        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Download file response: ', response.data)
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    deleteFile: async ({commit}, fileId) => {
        let config = {
            method: 'delete',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v2-svoc/file-metadata',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {id: fileId}
        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Delete file response: ', response.data)
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getManualAndAutoCounts: async ({commit}, data) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-paxcount/manual-and-auto-counts',
            headers: {
                'content-type': 'application/json'
            },
            params: {projectId: data.projectId, extDeviceId: data.extDeviceId, objType: data.objType, date: data.date}

        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getCountsSummary: async ({commit}, data) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-paxcount/counts-summary',
            headers: {
                'content-type': 'application/json'
            },
            params: {projectId: data.projectId, extDeviceId: data.extDeviceId, objType: data.objType}

        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getImagesMetaData: async ({commit}, data) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-paxcount/images-meta-data',
            headers: {
                'content-type': 'application/json'
            },
            data: data

        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getPaxCountProjects: async ({commit}) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-paxcount/projects',
            headers: {
                'content-type': 'application/json'
            }

        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getPaxCountProject: async ({commit}, projectId) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-paxcount/project',
            headers: {
                'content-type': 'application/json'
            },
            params: {projectId: projectId}

        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                return response.data
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    /**
     * Set store selected site
     * @param commit
     * @param selectedSite
     * @returns {Promise<void>}
     */
    setSelectedDataDevice: async ({commit}, selectedDataDevice) => {
        console.log('Set selected dataDevice: ', selectedDataDevice)
        commit('setSelectedDataDevice', selectedDataDevice)
    },

    /**
     * Set store selected project
     * @param commit
     * @param selectedProject
     * @returns {Promise<void>}
     */
    setSelectedProject: async ({commit}, selectedProject) => {
        commit('setSelectedProject', selectedProject)
    },

    /**
     * Set store search query
     * @param commit
     * @param queryData
     * @returns {Promise<void>}
     */
    setSearchQuery: async ({commit}, queryData) => {
        commit('setQueryData', queryData)
    },
    restoreState: async ({commit, state, getters, dispatch, rootGetters}, data) => {
        if (state.selectedProject == null) {
            await dispatch('getProjects', null, {root: true})
            let selectedProject = rootGetters.getProjects.find(project => project._id === data.projectId)
            if (selectedProject) {
                dispatch('setSelectedProject', selectedProject)
                if (state.selectedDataDevice === null) {
                    await dispatch('getSummaryData', selectedProject._id)
                    let selectedDataDevice = getters.getFilteredSites.find(dd => dd.id === data.dataDeviceId)
                    if (selectedDataDevice) {
                        await dispatch('setSelectedDataDevice', selectedDataDevice)
                        await dispatch('getConflictEvents', selectedDataDevice.id)
                        await dispatch('setTablePage', parseInt(data.pageNumber))
                    }
                }
            }
        }
    },
    setTableHeaderData: async ({commit}, newData) => {
        commit('setTableHeaderData', newData)
    },
    setSingleUserTableHeaderData: async ({commit}, newData) => {
        commit('setSingleUserTableHeaderData', newData)
    },
    resetTableHeaderData: async ({commit}) => {
        commit('resetTableHeaderData')
        commit('resetSingleUserTableHeaderData')
    },
    setTablePage: async ({commit}, pageNum) => {
        commit('setCurrentTablePage', pageNum)
    },
}

