<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="box p-2 pt-5">
          <apex-chart type="bar" height="390" :options="weeklyChartOptions" :series="weeklySeriesLine"></apex-chart>
        </div>
      </div>
      <div class="column">
        <div class="box p-2 pt-5">
          <apex-chart type="radar" height="390" :options="hourlyChartOptions" :series="hourlySeries"></apex-chart>
        </div>
      </div>
      <div class="column" v-if="showMatrixChart">
        <div class="box p-1 pt-0">
          <div class="m-3 mt-4 is-size-7">TOTAL EVENTS</div>
          <matrix-chart style="height: 398px;" :chart-data="matrixChartData"></matrix-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getFormattedHours} from "@/utils/utils";
import {mapGetters, mapState} from "vuex";
import MatrixChart from "@/modules/safety-module/components/matrix-chart/matrix-chart.vue";

export default {
  name: "site-charts",
  components: {MatrixChart},
  data() {
    return {
      loadingFirstChart: false,
      isFullPage: false,
      hourlyChartOptions: {
        chart: {
          height: 390,
          type: 'radar',
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          radar: {
            size: 110,
            polygons: {
              strokeColors: '#e9e9e9',
              fill: {
                colors: ['#f8f8f8', '#fff']
              }
            }
          }
        },
        title: {
          text: 'HOURLY EVENTS DISTRIBUTION',
          style: {
            fontSize:  '12px',
            fontWeight:  '500',
            color:  '#4a4a4a'
          }
        },
        colors: ['#E68600'],
        markers: {
          size: 3,
          colors: ['#fff'],
          strokeColor: '#9b5f01',
          strokeWidth: 1,
        },
        tooltip: {
          style: {
            fontSize: '16px'
          },
          y: {
            formatter: function (val) {
              return val
            }
          },
          x:{
            formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
              return getFormattedHours()[dataPointIndex]
            }
          }
        },
        xaxis: {
          categories: ['0-1', '1-2', '2-3', '3-4', '4-5', '5-6', '6-7', '7-8', '8-9', '9-10', '10-11', '11-12', '12-13', '13-14', '14-15', '15-16', '16-17', '17-18', '18-19', '19-20', '20-21', '21-22', '22-23', '23-0']
        },
        yaxis: {
          //TODO Calculate this value depending of max value
          tickAmount: 4,
          labels: {
            formatter: function (val, i) {
              return val
            }
          }
        }
      }
    }
  },
  mounted() {
    console.log('Site charts, selected site is: ', this.selectedSite)
  },
  methods: {},
  computed: {
    ...mapState({
      selectedDataDevice: state => state.safetyModule.selectedDataDevice,
      selectedProject: state => state.safetyModule.selectedProject,
    }),
    ...mapGetters({
      userRole:'getUserRole'
    }),
    donutSeries() {
      if (this.selectedDataDevice && this.selectedDataDevice.severityReport) {
        return Object.values(this.selectedDataDevice.severityReport)
      }
      return []

    },
    donutChartOptions() {
      if (this.selectedDataDevice && this.selectedDataDevice.severityReport) {
        return {
          chart: {
            type: 'donut',
            height: 347,
          },
          title: {
            text: 'TOTAL EVENTS',
            offsetY: 0,
            align: 'left',
              style: {
                fontSize:  '12px',
                fontWeight:  '500',
                color:  '#4a4a4a'
              }
          },
          labels: Object.keys(this.selectedDataDevice.severityReport),
          colors: ['#DA1E28', '#efa70d', '#3e8ed0'],
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              return opts.w.config.series[opts.seriesIndex]
            },
          },
          legend: {
            position: 'bottom'
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 10,
                  },
                  total: {
                    show: true,
                    showAlways: true,
                    fontSize: '22px',
                    fontWeight: 600,
                    label: `${Object.values(this.selectedDataDevice.severityReport).reduce((acc, val) => acc + val, 0)}`,
                    formatter: function (w) {
                      return '';
                    }
                  }
                }
              }
            }
          }
        }
      }
      return {}
    },
    hourlySeries() {
      if (this.selectedDataDevice && this.selectedDataDevice.hourlyReport) {
        return [{
          name: 'Number of events',
          data: Object.values(this.selectedDataDevice.hourlyReport),
        }]
      }
      return []
    },
    weeklyChartOptions() {
      if (this.selectedDataDevice && this.selectedDataDevice.weeklyReport) {
        return {
          chart: {
            height: 390,
            type: 'bar',
          },
          tooltip:{
            enabled: false,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          colors: ['#E68600'],
          xaxis: {
            categories: Object.keys(this.selectedDataDevice.weeklyReport),
            position: 'bottom',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: false,
            }
          },
          yaxis: {
            labels: {
              show: false,
              formatter: function (val) {
                return val;
              }
            }
          },
          title: {
            text: 'WEEKLY EVENTS DISTRIBUTION',
            offsetY: 0,
            align: 'left',
            style: {
              fontSize:  '12px',
              fontWeight:  '500',
              color:  '#4a4a4a'
            }
          }
        }
      }
      return {}
    },
    weeklySeriesLine() {
      if (this.selectedDataDevice && this.selectedDataDevice.weeklyReport) {
        return [{
          name: 'Weekly Incidents Distribution',
          data: Object.values(this.selectedDataDevice.weeklyReport)
        }]
      }

      return []
    },
    matrixChartData(){
      if (this.selectedDataDevice && this.selectedDataDevice.roadUserMatrixReport) {
        return this.selectedDataDevice.roadUserMatrixReport
      }

      return {}
    },
    showMatrixChart(){
      if (this.selectedProject && this.selectedProject.types && this.selectedProject.types.length === 0) {
        return true
      } else {
        return false
      }
    }

  }
}
</script>

<style scoped>

</style>